import React from 'react';

import { eligibleStocks } from 'eligibleStocks';

import { CompanyBreakdown } from 'components/CompanyBreakdown/CompanyBreakdown';
import { ReportsOverTime } from 'components/ReportsOverTime/ReportsOverTime';
import { useInputState } from 'hooks';

import { ReactComponent as SearchIcon } from './search.svg';

import classNames from './Home.module.css';
import { useSymbolStore } from 'data/symbolData';

export const Home = () => {
  const [selectedStock, setSelectedStock] = useSymbolStore((s) => [
    s.symbol,
    s.setSymbol,
  ]);
  const { companyOverview } = useSymbolStore((s) => ({
    companyOverview: s.companyOverview,
  }));
  const [search, onChangeSearch, setSearch] = useInputState('');

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Enter': {
        setSearch('');
        setSelectedStock(search);
      }
    }
  };

  const handleSelect = (s: string) => () => {
    if (s === companyOverview?.Symbol) {
      return;
    }
    setSearch('');
    setSelectedStock(s);
  };

  const visibleStocks = React.useMemo(() => {
    if (search.length > 0) {
      return eligibleStocks
        .filter((s) => s.toLowerCase().includes(search.toLowerCase()))
        .slice(0, 100);
    } else {
      return eligibleStocks.slice(0, 100);
    }
  }, [search]);

  React.useEffect(() => {
    if (!!selectedStock) {
      // loadSymbolData(selectedStock);
    }
  }, [selectedStock]);

  return (
    <div className={classNames.root}>
      <div className={classNames.strongPicksContainer}></div>
      <div style={{ maxWidth: 1400, margin: 'auto' }}>
        <div className={classNames.searchContainer}>
          <div className={classNames.searchIcon}>
            <SearchIcon />
          </div>
          <input
            className={classNames.search}
            value={search}
            onChange={onChangeSearch}
            onKeyDown={handleKeyDown}
            placeholder="Search symbols..."
          />
          <div className={classNames.selectMenu}>
            {!!search &&
              visibleStocks.map((s) => (
                <button
                  onClick={handleSelect(s)}
                  className={classNames.selectOption}
                >
                  {s}
                </button>
              ))}
          </div>
        </div>
        {selectedStock && (
          <div>
            <div className={classNames.companyContainer}>
              <span className={classNames.companyName}>
                {companyOverview?.Name}
              </span>
              <div
                style={{
                  display: 'flex',
                  marginTop: 16,
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: 400 }}>
                  <CompanyBreakdown overview={companyOverview} />
                </div>
                <div style={{ flex: 1 }}>
                  <ReportsOverTime />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
