export const getBigNumberAsString = (marketCap: number) => {
  if (marketCap % 1000000000000 < marketCap) {
    return `${(marketCap / 1000000000000).toFixed(2)}T`;
  } else if (marketCap % 1000000000 < marketCap) {
    return `${(marketCap / 1000000000).toFixed(2)}B`;
  } else if (marketCap % 1000000 < marketCap) {
    return `${(marketCap / 1000000).toFixed(2)}M`;
  }
  return `${marketCap}`;
};

export const toPercent = (v: number): string => {
  return `${(v * 100).toFixed(2)}%`;
};

export const toRounded = (v: number, numDigits: number = 2): string => {
  return v.toFixed(numDigits);
};

export const parseFloatWithNone = (s: string): number => {
  if (s === 'None') {
    return 0;
  }
  return parseFloat(s);
};

export const camelCaseToCapital = (s: string): string => {
  // Add a space before the uppercase letters in the string
  let spacedStr = s.replace(/([A-Z])/g, ' $1');

  // Capitalize the first letter of each word in the spaced string
  return spacedStr.replace(/\b\w/g, (match) => {
    return match.toUpperCase();
  });
};
