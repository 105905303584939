import { API } from 'api';
import {
  AVBalanceSheet,
  AVCashFlow,
  AVCompanyOverview,
  AVDaily,
  AVIncomeStatement,
} from 'models';
import { create } from 'zustand';

interface SymbolState {
  balanceSheet: null | AVBalanceSheet;
  incomeStatement: null | AVIncomeStatement;
  cashFlow: null | AVCashFlow;
  companyOverview: null | AVCompanyOverview;
  priceData: null | AVDaily;
  symbol: null | string;
  setSymbol: (s: string) => void;
  loadStatement: (
    symbol: string,
    statement:
      | 'balance-sheet'
      | 'income-statement'
      | 'cash-flow'
      | 'price-data',
  ) => void;
  resetStore: () => void;
}

export const useSymbolStore = create<SymbolState>((set) => ({
  balanceSheet: null,
  incomeStatement: null,
  cashFlow: null,
  companyOverview: null,
  priceData: null,
  symbol: null,
  setSymbol: (symbol) => {
    set({
      symbol,
      companyOverview: null,
      balanceSheet: null,
      incomeStatement: null,
      cashFlow: null,
      priceData: null,
    });
    API.getCompanyOverview(symbol).then((companyOverview) =>
      set({ companyOverview }),
    );
  },
  resetStore: () =>
    set({ balanceSheet: null, incomeStatement: null, cashFlow: null }),
  loadStatement: (
    symbol: string,
    statement:
      | 'balance-sheet'
      | 'income-statement'
      | 'cash-flow'
      | 'price-data',
  ) => {
    switch (statement) {
      case 'balance-sheet':
        API.getBalanceSheet(symbol).then((balanceSheet) =>
          set({ balanceSheet }),
        );
        break;
      case 'income-statement':
        API.getIncomeStatement(symbol).then((incomeStatement) =>
          set({ incomeStatement }),
        );
        break;
      case 'cash-flow':
        API.getCashFlow(symbol).then((cashFlow) => set({ cashFlow }));
        break;
      case 'price-data':
        API.getDailyData(symbol).then((priceData) => set({ priceData }));
    }
  },
}));
