import React from 'react';
import cx from 'classnames';
import Highcharts from 'highcharts';
import brandDark from 'highcharts/themes/brand-dark';

import { AVBalanceSheetReport, AVIncomeStatement } from 'models';

import classNames from './ReportsOverTime.module.css';
import { camelCaseToCapital, getBigNumberAsString } from 'utils';
import { useSymbolStore } from 'data/symbolData';
import { CandlestickChart } from 'components/CandlestickChart/CandlestickChart';

brandDark(Highcharts);

export const ReportsOverTime = () => {
  const {
    balanceSheet,
    incomeStatement,
    cashFlow,
    loadStatement,
    symbol,
    priceData,
    companyOverview,
  } = useSymbolStore((s) => ({
    balanceSheet: s.balanceSheet,
    incomeStatement: s.incomeStatement,
    cashFlow: s.cashFlow,
    loadStatement: s.loadStatement,
    symbol: s.symbol,
    priceData: s.priceData,
    companyOverview: s.companyOverview,
  }));

  const [reportType, setReportType] = React.useState<
    'balance-sheet' | 'income-statement' | 'cash-flow'
  >('balance-sheet');
  const [selectedReportSubset, setSelectedReportSubset] = React.useState<
    'quarterlyReports' | 'annualReports'
  >('quarterlyReports');
  const [selectedField, setSelectedField] = React.useState<
    keyof AVBalanceSheetReport | keyof AVIncomeStatement
  >('totalAssets');
  const [showPrice, setShowPrice] = React.useState(false);

  const selectedData = React.useMemo(() => {
    switch (reportType) {
      case 'balance-sheet':
        return balanceSheet;
      case 'income-statement':
        return incomeStatement;
      case 'cash-flow':
        return cashFlow;
    }
  }, [reportType, balanceSheet, incomeStatement, cashFlow]);

  const fieldKeys = React.useMemo(() => {
    if (selectedData === null) {
      return [];
    }

    return Object.keys(selectedData?.[selectedReportSubset][0]);
  }, [selectedData, selectedReportSubset]);

  React.useEffect(() => {
    if (!selectedData && symbol) {
      loadStatement(symbol, reportType);
    }
  }, [symbol, selectedData, loadStatement, reportType]);

  React.useEffect(() => {
    if (showPrice && !priceData && symbol) {
      loadStatement(symbol, 'price-data');
    }
  }, [showPrice, priceData, symbol, loadStatement]);

  React.useEffect(() => {
    if (selectedData === null || showPrice) {
      return;
    }

    const categories = selectedData[selectedReportSubset]
      .map((d) => d.fiscalDateEnding)
      .reverse();
    const chartData = selectedData[selectedReportSubset]
      .map((d) => {
        if (selectedField in d) {
          return (d as any)[selectedField];
        }
      })
      .reverse();

    Highcharts.chart('reports-over-time-chart', {
      chart: { height: 500 },
      title: {
        text: `${symbol} ${camelCaseToCapital(selectedField)} Over Time`,
      },
      xAxis: {
        categories,
      },
      yAxis: {
        title: {
          text: '$',
        },
      },
      series: [
        {
          type: 'column',
          name: camelCaseToCapital(selectedField),
          data: chartData,
        },
      ],
      tooltip: {
        formatter: function () {
          return getBigNumberAsString(this.point.y || 0);
        },
      },
    });
  }, [
    reportType,
    selectedField,
    selectedReportSubset,
    selectedData,
    symbol,
    showPrice,
  ]);

  if (selectedData === null) {
    return <div className="t-square-loader" />;
  }

  return (
    <div className={classNames.root}>
      <div className={classNames.fieldSelectContainer}>
        {!showPrice && (
          <>
            <select
              className={classNames.fieldSelect}
              name="reportType"
              id="reportType"
              onChange={(e) => {
                const newVal = e.target.value;
                if (
                  newVal === 'balance-sheet' ||
                  newVal === 'income-statement' ||
                  newVal === 'cash-flow'
                ) {
                  setReportType(newVal);
                }
              }}
              value={reportType}
            >
              <option value="balance-sheet">Balance Sheet</option>
              <option value="income-statement">Income Statement</option>
              <option value="cash-flow">Cash Flow</option>
            </select>
            <div className="t-subheader">&nbsp;Over Time&nbsp;</div>
            <select
              className={classNames.fieldSelect}
              name="reportSubset"
              id="reportSubset"
              onChange={(e) => {
                const newVal = e.target.value;
                if (
                  newVal === 'quarterlyReports' ||
                  newVal === 'annualReports'
                ) {
                  setSelectedReportSubset(newVal);
                }
              }}
              value={selectedReportSubset}
            >
              <option value="quarterlyReports">Quarterly</option>
              <option value="annualReports">Annual</option>
            </select>
            <select
              className={classNames.fieldSelect}
              name="selectedField"
              id="selectedField"
              onChange={(e) => {
                setSelectedField(e.target.value as keyof AVBalanceSheetReport);
              }}
              value={selectedField}
            >
              {fieldKeys.map((field) => (
                <option value={field} key={`balance-sheet-field-${field}`}>
                  {camelCaseToCapital(field)}
                </option>
              ))}
            </select>
          </>
        )}
        {showPrice && (
          <div
            className={classNames.priceHeader}
          >{`${symbol} (${companyOverview?.Exchange})`}</div>
        )}
        <button
          onClick={() => {
            if (!priceData && symbol) {
              loadStatement(symbol, 'price-data');
            }
            setShowPrice((s) => !s);
          }}
          className={cx(
            classNames.iconButton,
            showPrice && classNames.iconButtonFilled,
          )}
        >
          $
        </button>
      </div>
      <div style={{ height: 300 }}>
        {!showPrice && <div id="reports-over-time-chart" />}
        {showPrice && symbol && (
          <CandlestickChart
            symbol={symbol}
            data={priceData}
            overview={companyOverview}
            height={'60%'}
          />
        )}
      </div>
    </div>
  );
};
