import { AVCompanyOverview } from 'models';
import { getBigNumberAsString, toPercent, toRounded } from 'utils';

const getBigMoneyAsString = (v: number) => `$${getBigNumberAsString(v)}`;

export interface Field {
  property: keyof AVCompanyOverview;
  label: string;
  get?: (val: any) => string;
}
export const fields: Field[] = [
  {
    property: 'DividendYield',
    label: 'Div. Yield',
    get: toPercent,
  },
  { property: 'DividendDate', label: 'Div. Date' },
  { property: 'EPS', label: 'EPS', get: toRounded },
  {
    property: 'MarketCapitalization',
    label: 'Market Cap',
    get: getBigMoneyAsString,
  },
  {
    property: 'ProfitMargin',
    label: 'Profit Margin',
    get: toPercent,
  },
  { property: 'Sector', label: 'Sector' },
  { property: 'PERatio', label: 'PE Ratio', get: toRounded },
  { property: 'Beta', label: 'Beta', get: toRounded },
  {
    property: 'EBITDA',
    label: 'EBITDA',
    get: getBigMoneyAsString,
  },
  {
    property: 'RevenueTTM',
    label: 'Revenue TTM',
    get: getBigMoneyAsString,
  },
  {
    property: 'GrossProfitTTM',
    label: 'Gross Profit (TTM)',
    get: getBigMoneyAsString,
  },
  { property: 'Industry', label: 'Industry' },
  {
    property: 'QuarterlyEarningsGrowthYOY',
    label: 'Quarterly Earnings Growth YOY',
    get: toPercent,
  },
  {
    property: 'QuarterlyRevenueGrowthYOY',
    label: 'Quarterly Revenue Growth YOY',
    get: toPercent,
  },
  {
    property: 'SharesOutstanding',
    label: 'Shares Outstanding',
    get: getBigNumberAsString,
  },
];
