import {
	AVBalanceSheet,
	AVBalanceSheetReport,
	AVCashFlow,
	AVCashFlowReport,
	AVCompanyOverview,
	AVIncomeStatement,
	AVIncomeStatementReport,
} from 'models';
import { parseFloatWithNone } from 'utils';

export const convertAVCompanyOverview = (resp: any): AVCompanyOverview => ({
	'50DayMovingAverage': parseFloatWithNone(resp['50DayMovingAverage']),
	'52WeekHigh': parseFloatWithNone(resp['52WeekHigh']),
	'52WeekLow': parseFloatWithNone(resp['52WeekLow']),
	'200DayMovingAverage': parseFloatWithNone(resp['200DayMovingAverage']),
	Address: resp.Address,
	AnalystTargetPrice: resp.AnalystTargetPrice,
	AssetType: resp.AssetType,
	Beta: parseFloatWithNone(resp.Beta),
	BookValue: parseFloatWithNone(resp.BookValue),
	CIK: parseFloatWithNone(resp.CIK),
	Country: resp.Country,
	Currency: resp.Currency,
	Description: resp.Description,
	DilutedEPSTTM: parseFloatWithNone(resp.DilutedEPSTTM),
	DividendDate: resp.DividendDate,
	DividendPerShare: parseFloatWithNone(resp.DividendPerShare),
	DividendYield: parseFloatWithNone(resp.DividendYield),
	EBITDA: parseFloatWithNone(resp.EBITDA),
	EPS: parseFloatWithNone(resp.EPS),
	EVToEBITDA: parseFloatWithNone(resp.EVToEBITDA),
	EVToRevenue: parseFloatWithNone(resp.EVToRevenue),
	ExDividendDate: resp.ExDividendDate,
	Exchange: resp.Exchange,
	FiscalYearEnd: resp.FiscalYearEnd,
	ForwardPE: parseFloatWithNone(resp.ForwardPE),
	GrossProfitTTM: parseFloatWithNone(resp.GrossProfitTTM),
	Industry: resp.Industry,
	LatestQuarter: resp.LatestQuarter,
	MarketCapitalization: parseFloatWithNone(resp.MarketCapitalization),
	Name: resp.Name,
	OperatingMarginTTM: parseFloatWithNone(resp.OperatingMarginTTM),
	PEGRatio: parseFloatWithNone(resp.PEGRatio),
	PERatio: parseFloatWithNone(resp.PERatio),
	PriceToBookRatio: parseFloatWithNone(resp.PriceToBookRatio),
	PriceToSalesRatioTTM: parseFloatWithNone(resp.PriceToSalesRatioTTM),
	ProfitMargin: parseFloatWithNone(resp.ProfitMargin),
	QuarterlyEarningsGrowthYOY: parseFloatWithNone(
		resp.QuarterlyEarningsGrowthYOY
	),
	QuarterlyRevenueGrowthYOY: parseFloatWithNone(resp.QuarterlyRevenueGrowthYOY),
	ReturnOnAssetsTTM: parseFloatWithNone(resp.ReturnOnAssetsTTM),
	ReturnOnEquityTTM: parseFloatWithNone(resp.ReturnOnEquityTTM),
	RevenuePerShareTTM: parseFloatWithNone(resp.RevenuePerShareTTM),
	RevenueTTM: parseFloatWithNone(resp.RevenueTTM),
	Sector: resp.Sector,
	SharesOutstanding: parseFloatWithNone(resp.SharesOutstanding),
	Symbol: resp.Symbol,
	TrailingPE: parseFloatWithNone(resp.TrailingPE),
});

const convertAVBalanceSheetReport = (report: any): AVBalanceSheetReport => ({
	accumulatedDepreciationAmortizationPPE: parseFloatWithNone(
		report.accumulatedDepreciationAmortizationPPE
	),
	capitalLeaseObligations: parseFloatWithNone(report.capitalLeaseObligations),
	cashAndCashEquivalentsAtCarryingValue: parseFloatWithNone(
		report.cashAndCashEquivalentsAtCarryingValue
	),
	cashAndShortTermInvestments: parseFloatWithNone(
		report.cashAndShortTermInvestments
	),
	commonStock: parseFloatWithNone(report.commonStock),
	commonStockSharesOutstanding: parseFloatWithNone(
		report.commonStockSharesOutstanding
	),
	currentAccountsPayable: parseFloatWithNone(report.currentAccountsPayable),
	currentDebt: parseFloatWithNone(report.currentDebt),
	currentLongTermDebt: parseFloatWithNone(report.currentLongTermDebt),
	currentNetReceivables: parseFloatWithNone(report.currentNetReceivables),
	deferredRevenue: parseFloatWithNone(report.deferredRevenue),
	fiscalDateEnding: report.fiscalDateEnding,
	goodwill: parseFloatWithNone(report.goodwill),
	intangibleAssets: parseFloatWithNone(report.intangibleAssets),
	intangibleAssetsExcludingGoodwill: parseFloatWithNone(
		report.intangibleAssetsExcludingGoodwill
	),
	inventory: parseFloatWithNone(report.inventory),
	investments: parseFloatWithNone(report.investments),
	longTermDebt: parseFloatWithNone(report.longTermDebt),
	longTermDebtNoncurrent: parseFloatWithNone(report.longTermDebtNoncurrent),
	longTermInvestments: parseFloatWithNone(report.longTermInvestments),
	otherCurrentAssets: parseFloatWithNone(report.otherCurrentAssets),
	otherCurrentLiabilities: parseFloatWithNone(report.otherCurrentLiabilities),
	otherNonCurrentAssets: parseFloatWithNone(report.otherNonCurrentAssets),
	otherNonCurrentLiabilities: parseFloatWithNone(
		report.otherNonCurrentLiabilities
	),
	propertyPlantEquipment: parseFloatWithNone(report.propertyPlantEquipment),
	reportedCurrency: report.reportedCurrency,
	retainedEarnings: parseFloatWithNone(report.retainedEarnings),
	shortLongTermDebtTotal: parseFloatWithNone(report.shortLongTermDebtTotal),
	shortTermDebt: parseFloatWithNone(report.shortTermDebt),
	shortTermInvestments: parseFloatWithNone(report.shortTermInvestments),
	totalAssets: parseFloatWithNone(report.totalAssets),
	totalCurrentAssets: parseFloatWithNone(report.totalCurrentAssets),
	totalCurrentLiabilities: parseFloatWithNone(report.totalCurrentLiabilities),
	totalLiabilities: parseFloatWithNone(report.totalLiabilities),
	totalNonCurrentAssets: parseFloatWithNone(report.totalNonCurrentAssets),
	totalNonCurrentLiabilities: parseFloatWithNone(
		report.totalNonCurrentLiabilities
	),
	totalShareholderEquity: parseFloatWithNone(report.totalShareholderEquity),
	treasuryStock: parseFloatWithNone(report.treasuryStock),
});

export const convertAVBalanceSheet = (resp: any): AVBalanceSheet => ({
	quarterlyReports: resp.quarterlyReports.map(convertAVBalanceSheetReport),
	annualReports: resp.annualReports.map(convertAVBalanceSheetReport),
	symbol: resp.symbol,
});

const convertAVIncomeStatementReport = (
	report: any
): AVIncomeStatementReport => ({
	comprehensiveIncomeNetOfTax: parseFloatWithNone(
		report.comprehensiveIncomeNetOfTax
	),
	costOfRevenue: parseFloatWithNone(report.costOfRevenue),
	costofGoodsAndServicesSold: parseFloatWithNone(
		report.costofGoodsAndServicesSold
	),
	depreciation: parseFloatWithNone(report.depreciation),
	depreciationAndAmortization: parseFloatWithNone(
		report.depreciationAndAmortization
	),
	ebit: parseFloatWithNone(report.ebit),
	ebitda: parseFloatWithNone(report.ebitda),
	fiscalDateEnding: report.fiscalDateEnding,
	grossProfit: parseFloatWithNone(report.grossProfit),
	incomeBeforeTax: parseFloatWithNone(report.incomeBeforeTax),
	incomeTaxExpense: parseFloatWithNone(report.incomeTaxExpense),
	interestAndDebtExpense: parseFloatWithNone(report.interestAndDebtExpense),
	interestExpense: parseFloatWithNone(report.interestExpense),
	interestIncome: parseFloatWithNone(report.interestIncome),
	investmentIncomeNet: parseFloatWithNone(report.investmentIncomeNet),
	netIncome: parseFloatWithNone(report.netIncome),
	netIncomeFromContinuingOperations: parseFloatWithNone(
		report.netIncomeFromContinuingOperations
	),
	netInterestIncome: parseFloatWithNone(report.netInterestIncome),
	nonInterestIncome: parseFloatWithNone(report.nonInterestIncome),
	operatingExpenses: parseFloatWithNone(report.operatingExpenses),
	operatingIncome: parseFloatWithNone(report.operatingIncome),
	otherNonOperatingIncome: parseFloatWithNone(report.otherNonOperatingIncome),
	reportedCurrency: parseFloatWithNone(report.reportedCurrency),
	researchAndDevelopment: parseFloatWithNone(report.researchAndDevelopment),
	sellingGeneralAndAdministrative: parseFloatWithNone(
		report.sellingGeneralAndAdministrative
	),
	totalRevenue: parseFloatWithNone(report.totalRevenue),
});

export const convertAVIncomeStatement = (resp: any): AVIncomeStatement => ({
	quarterlyReports: resp.quarterlyReports.map(convertAVIncomeStatementReport),
	annualReports: resp.annualReports.map(convertAVIncomeStatementReport),
	symbol: resp.symbol,
});

const convertAVCashFlowReport = (report: any): AVCashFlowReport => ({
	capitalExpenditures: parseFloatWithNone(report.capitalExpenditures),
	cashflowFromFinancing: parseFloatWithNone(report.cashflowFromFinancing),
	cashflowFromInvestment: parseFloatWithNone(report.cashflowFromInvestment),
	changeInCashAndCashEquivalents: parseFloatWithNone(
		report.changeInCashAndCashEquivalents
	),
	changeInExchangeRate: parseFloatWithNone(report.changeInExchangeRate),
	changeInInventory: parseFloatWithNone(report.changeInInventory),
	changeInOperatingAssets: parseFloatWithNone(report.changeInOperatingAssets),
	changeInOperatingLiabilities: parseFloatWithNone(
		report.changeInOperatingLiabilities
	),
	changeInReceivables: parseFloatWithNone(report.changeInReceivables),
	depreciationDepletionAndAmortization: parseFloatWithNone(
		report.depreciationDepletionAndAmortization
	),
	dividendPayout: parseFloatWithNone(report.dividendPayout),
	dividendPayoutCommonStock: parseFloatWithNone(
		report.dividendPayoutCommonStock
	),
	dividendPayoutPreferredStock: parseFloatWithNone(
		report.dividendPayoutPreferredStock
	),
	fiscalDateEnding: report.fiscalDateEnding,
	netIncome: parseFloatWithNone(report.netIncome),
	operatingCashflow: parseFloatWithNone(report.operatingCashflow),
	paymentsForOperatingActivities: parseFloatWithNone(
		report.paymentsForOperatingActivities
	),
	paymentsForRepurchaseOfCommonStock: parseFloatWithNone(
		report.paymentsForRepurchaseOfCommonStock
	),
	paymentsForRepurchaseOfEquity: parseFloatWithNone(
		report.paymentsForRepurchaseOfEquity
	),
	paymentsForRepurchaseOfPreferredStock: parseFloatWithNone(
		report.paymentsForRepurchaseOfPreferredStock
	),
	proceedsFromIssuanceOfCommonStock: parseFloatWithNone(
		report.proceedsFromIssuanceOfCommonStock
	),
	proceedsFromIssuanceOfLongTermDebtAndCapitalSecuritiesNet: parseFloatWithNone(
		report.proceedsFromIssuanceOfLongTermDebtAndCapitalSecuritiesNet
	),
	proceedsFromIssuanceOfPreferredStock: parseFloatWithNone(
		report.proceedsFromIssuanceOfPreferredStock
	),
	proceedsFromOperatingActivities: parseFloatWithNone(
		report.proceedsFromOperatingActivities
	),
	proceedsFromRepaymentsOfShortTermDebt: parseFloatWithNone(
		report.proceedsFromRepaymentsOfShortTermDebt
	),
	proceedsFromRepurchaseOfEquity: parseFloatWithNone(
		report.proceedsFromRepurchaseOfEquity
	),
	proceedsFromSaleOfTreasuryStock: parseFloatWithNone(
		report.proceedsFromSaleOfTreasuryStock
	),
	profitLoss: parseFloatWithNone(report.profitLoss),
	reportedCurrency: report.reportedCurrency,
});

export const convertAVCashFlow = (resp: any): AVCashFlow => ({
	quarterlyReports: resp.quarterlyReports.map(convertAVCashFlowReport),
	annualReports: resp.annualReports.map(convertAVCashFlowReport),
	symbol: resp.symbol,
});
