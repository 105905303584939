import {
	convertAVBalanceSheet,
	convertAVCashFlow,
	convertAVCompanyOverview,
	convertAVIncomeStatement,
} from 'model-converters';

export type ValueOf<T> = T[keyof T];

export type AVNumber = number | 'None';

export enum DataType {
	Daily = 'daily',
	CompanyOverview = 'company-overview',
	IncomeStatement = 'income-statement',
	BalanceSheet = 'balance-sheet',
	CashFlow = 'cash-flow',
}

export interface AVCompanyOverview {
	'50DayMovingAverage': number;
	'52WeekHigh': number;
	'52WeekLow': number;
	'200DayMovingAverage': number;
	Address: string;
	AnalystTargetPrice: number;
	AssetType: 'Common Stock' | string;
	Beta: number;
	BookValue: number;
	CIK: number;
	Country: 'USA' | string;
	Currency: 'USD' | string;
	Description: string;
	DilutedEPSTTM: number;
	DividendDate: string;
	DividendPerShare: number;
	DividendYield: number;
	EBITDA: number;
	EPS: number;
	EVToEBITDA: number;
	EVToRevenue: number;
	ExDividendDate: string;
	Exchange: 'NASDAQ' | string;
	FiscalYearEnd: 'September' | string;
	ForwardPE: number;
	GrossProfitTTM: number;
	Industry: string;
	LatestQuarter: string;
	MarketCapitalization: number;
	Name: string;
	OperatingMarginTTM: number;
	PEGRatio: number;
	PERatio: number;
	PriceToBookRatio: number;
	PriceToSalesRatioTTM: number;
	ProfitMargin: number;
	QuarterlyEarningsGrowthYOY: number;
	QuarterlyRevenueGrowthYOY: number;
	ReturnOnAssetsTTM: number;
	ReturnOnEquityTTM: number;
	RevenuePerShareTTM: number;
	RevenueTTM: number;
	Sector: 'TECHNOLOGY' | string;
	SharesOutstanding: number;
	Symbol: string;
	TrailingPE: number;
}

export interface AVDailyPrice {
	'1. open': string;
	'2. high': string;
	'3. low': string;
	'4. close': string;
	'5. adjusted close': string;
	'6. volume': string;
	'7. dividend amount': string;
	'8. split coefficient': string;
}
export interface AVDaily {
	'Meta Data': {
		Information: string;
		Symbol: string;
		'Last Refreshed': string;
		'Output Size': string;
		'Time Zone': string;
	};
	'Time Series (Daily)': Record<string, AVDailyPrice>;
}

export interface AVIncomeStatementReport {
	comprehensiveIncomeNetOfTax: AVNumber;
	costOfRevenue: AVNumber;
	costofGoodsAndServicesSold: AVNumber;
	depreciation: AVNumber;
	depreciationAndAmortization: AVNumber;
	ebit: AVNumber;
	ebitda: AVNumber;
	fiscalDateEnding: string;
	grossProfit: AVNumber;
	incomeBeforeTax: AVNumber;
	incomeTaxExpense: AVNumber;
	interestAndDebtExpense: AVNumber;
	interestExpense: AVNumber;
	interestIncome: AVNumber;
	investmentIncomeNet: AVNumber;
	netIncome: AVNumber;
	netIncomeFromContinuingOperations: AVNumber;
	netInterestIncome: AVNumber;
	nonInterestIncome: AVNumber;
	operatingExpenses: AVNumber;
	operatingIncome: AVNumber;
	otherNonOperatingIncome: AVNumber;
	reportedCurrency: AVNumber;
	researchAndDevelopment: AVNumber;
	sellingGeneralAndAdministrative: AVNumber;
	totalRevenue: AVNumber;
}

export interface AVIncomeStatement {
	symbol: string;
	annualReports: Array<AVIncomeStatementReport>;
	quarterlyReports: Array<AVIncomeStatementReport>;
}

export interface AVBalanceSheetReport {
	accumulatedDepreciationAmortizationPPE: AVNumber;
	capitalLeaseObligations: AVNumber;
	cashAndCashEquivalentsAtCarryingValue: AVNumber;
	cashAndShortTermInvestments: AVNumber;
	commonStock: AVNumber;
	commonStockSharesOutstanding: AVNumber;
	currentAccountsPayable: AVNumber;
	currentDebt: AVNumber;
	currentLongTermDebt: AVNumber;
	currentNetReceivables: AVNumber;
	deferredRevenue: AVNumber;
	fiscalDateEnding: string;
	goodwill: AVNumber;
	intangibleAssets: AVNumber;
	intangibleAssetsExcludingGoodwill: AVNumber;
	inventory: AVNumber;
	investments: AVNumber;
	longTermDebt: AVNumber;
	longTermDebtNoncurrent: AVNumber;
	longTermInvestments: AVNumber;
	otherCurrentAssets: AVNumber;
	otherCurrentLiabilities: AVNumber;
	otherNonCurrentAssets: AVNumber;
	otherNonCurrentLiabilities: AVNumber;
	propertyPlantEquipment: AVNumber;
	reportedCurrency: 'USD' | string;
	retainedEarnings: AVNumber;
	shortLongTermDebtTotal: AVNumber;
	shortTermDebt: AVNumber;
	shortTermInvestments: AVNumber;
	totalAssets: AVNumber;
	totalCurrentAssets: AVNumber;
	totalCurrentLiabilities: AVNumber;
	totalLiabilities: AVNumber;
	totalNonCurrentAssets: AVNumber;
	totalNonCurrentLiabilities: AVNumber;
	totalShareholderEquity: AVNumber;
	treasuryStock: AVNumber;
}

export interface AVBalanceSheet {
	symbol: string;
	quarterlyReports: AVBalanceSheetReport[];
	annualReports: AVBalanceSheetReport[];
}

export interface AVCashFlowReport {
	capitalExpenditures: AVNumber;
	cashflowFromFinancing: AVNumber;
	cashflowFromInvestment: AVNumber;
	changeInCashAndCashEquivalents: AVNumber;
	changeInExchangeRate: AVNumber;
	changeInInventory: AVNumber;
	changeInOperatingAssets: AVNumber;
	changeInOperatingLiabilities: AVNumber;
	changeInReceivables: AVNumber;
	depreciationDepletionAndAmortization: AVNumber;
	dividendPayout: AVNumber;
	dividendPayoutCommonStock: AVNumber;
	dividendPayoutPreferredStock: AVNumber;
	fiscalDateEnding: string;
	netIncome: AVNumber;
	operatingCashflow: AVNumber;
	paymentsForOperatingActivities: AVNumber;
	paymentsForRepurchaseOfCommonStock: AVNumber;
	paymentsForRepurchaseOfEquity: AVNumber;
	paymentsForRepurchaseOfPreferredStock: AVNumber;
	proceedsFromIssuanceOfCommonStock: AVNumber;
	proceedsFromIssuanceOfLongTermDebtAndCapitalSecuritiesNet: AVNumber;
	proceedsFromIssuanceOfPreferredStock: AVNumber;
	proceedsFromOperatingActivities: AVNumber;
	proceedsFromRepaymentsOfShortTermDebt: AVNumber;
	proceedsFromRepurchaseOfEquity: AVNumber;
	proceedsFromSaleOfTreasuryStock: AVNumber;
	profitLoss: AVNumber;
	reportedCurrency: 'USD' | string;
}

export interface AVCashFlow {
	symbol: string;
	quarterlyReports: AVCashFlowReport[];
	annualReports: AVCashFlowReport[];
}

export const getModelConverter = (dataType: DataType) => {
	switch (dataType) {
		case DataType.CompanyOverview:
			return convertAVCompanyOverview;
		case DataType.BalanceSheet:
			return convertAVBalanceSheet;
		case DataType.IncomeStatement:
			return convertAVIncomeStatement;
		case DataType.CashFlow:
			return convertAVCashFlow;
	}
};
