import { AVCompanyOverview } from 'models';
import cx from 'classnames';

import classNames from './CompanyBreakdown.module.css';
import { Field, fields } from './fields';
import usePinnedFields from 'hooks';

interface CompanyBreakdownProps {
  overview: AVCompanyOverview | null;
}

export const CompanyBreakdown = ({
  overview,
}: CompanyBreakdownProps): JSX.Element => {
  const [pinnedFields, setPinnedFields] = usePinnedFields();

  const togglePinField = (field: Field) => {
    if (pinnedFields.find((f) => f.property === field.property)) {
      setPinnedFields(
        pinnedFields.filter((f) => f.property !== field.property),
      );
    } else {
      setPinnedFields([...pinnedFields, field]);
    }
  };

  const renderFieldCard = (field?: Field, isPinned?: boolean) => {
    if (!overview || !field) {
      return null;
    }

    const val = field.get
      ? field.get(overview[field.property])
      : overview[field.property];

    return (
      <div
        className={cx(classNames.item, isPinned && classNames.pinnedItem)}
        key={`cb-item-${field.property}`}
        onClick={() => togglePinField(field)}
      >
        <div className={classNames.itemLabel}>{field.label}</div>
        <div className={classNames.itemValue}>{val}</div>
      </div>
    );
  };

  if (!overview) {
    return <span />;
  }

  return (
    <div className={classNames.fieldsContainer}>
      {pinnedFields.map((pF) =>
        renderFieldCard(
          fields.find((f) => f.property === pF.property),
          true,
        ),
      )}
      {fields.map((field) =>
        !pinnedFields.find((f) => f.property === field.property)
          ? renderFieldCard(field)
          : null,
      )}
    </div>
  );
};
