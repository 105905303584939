import { Field } from 'components/CompanyBreakdown/fields';
import React from 'react';

export const useInputState = (
  defaultState: string,
): [
  string,
  (e: React.ChangeEvent<HTMLInputElement>) => void,
  (s: string) => void,
] => {
  const [state, setState] = React.useState<string>(defaultState || '');
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setState(e.target.value || ''),
    [setState],
  );

  return [state, onChange, setState];
};

const usePinnedFields = (): [Field[], (fields: Field[]) => void] => {
  const [pinnedFields, _setPinnedFields] = React.useState<Field[]>([]);

  React.useEffect(() => {
    // Fetch pinnedFields from local storage on component mount
    const storedPinnedFields = localStorage.getItem('pinnedFields');
    if (storedPinnedFields) {
      setPinnedFields(JSON.parse(storedPinnedFields));
    }
  }, []);

  const syncLocalStorage = (fields: Field[]) => {
    localStorage.setItem('pinnedFields', JSON.stringify(fields));
  };

  const setPinnedFields = (fields: Field[]) => {
    _setPinnedFields(fields);
    syncLocalStorage(fields);
  };

  return [pinnedFields, setPinnedFields];
};

export default usePinnedFields;
