import React from 'react';
import { DateTime } from 'luxon';
import Highcharts from 'highcharts/highstock';
import brandDark from 'highcharts/themes/brand-dark';

import { AVCompanyOverview, AVDaily } from 'models';

import { Loading } from 'components/Loading/Loading';

import classNames from './CandlestickChart.module.css';

brandDark(Highcharts);

interface CandlestickChartProps {
  data: AVDaily | null;
  overview: AVCompanyOverview | null;
  symbol: string;
  height?: number | string;
}

export const CandlestickChart = ({
  data,
  symbol,
  overview,
  height,
}: CandlestickChartProps): JSX.Element => {
  const [errorRenderingChart, setErrorRenderingChart] = React.useState(false);

  const renderChart = () => {
    if (!data || !overview) {
      return;
    }

    try {
      let chartData = Object.entries(data['Time Series (Daily)'])
        .map(([date, d]) => [
          DateTime.fromISO(date).toMillis(),
          parseFloat(d['1. open']),
          parseFloat(d['2. high']),
          parseFloat(d['3. low']),
          parseFloat(d['4. close']),
        ])
        .slice(0, 1500);
      chartData.sort((a, b) => {
        return a[0] - b[0];
      });

      const chart = Highcharts.stockChart(`${symbol}-chart-container`, {
        rangeSelector: {
          selected: 1,
        },

        // title: {
        //   text: `${symbol} (${overview.Exchange})`,
        // },

        chart: { height: height ?? 350 },

        series: [
          {
            type: 'candlestick',
            name: `Stock Price`,
            data: chartData,
            dataGrouping: {
              units: [
                ['day', [1]],
                [
                  'week', // unit name
                  [1], // allowed multiples
                ],
                ['month', [1, 2, 3, 4, 6]],
                ['year', [1]],
              ],
            },
          },
        ],
      });

      chart.setSize(null);

      return () => chart.destroy();
    } catch (err) {
      console.error(err);
      setErrorRenderingChart(true);
    }
  };

  React.useEffect(() => {
    renderChart();
  }, [data, symbol, overview, height]);

  if (!data || !overview) {
    return <Loading />;
  }

  return (
    <div className={classNames.wrapper}>
      <div id={`${symbol}-chart-container`} />
      {errorRenderingChart && <div>Error rendering chart</div>}
    </div>
  );
};
