import { Home } from './components/Home/Home';

import * as classNames from './App.module.css';

function App() {
	return (
		<div>
			<Home />
		</div>
	);
}

export default App;
