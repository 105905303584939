import {
	AVBalanceSheet,
	AVCashFlow,
	AVCompanyOverview,
	AVDaily,
	AVIncomeStatement,
	DataType,
	getModelConverter,
} from 'models';

export class API {
	// ===== Core ===== //

	static _getCachedDataUrl = (symbol: string, dataType: string): string => {
		return process.env.NODE_ENV === 'development'
			? `http://localhost:8080/api/v1/cached/${symbol}/${dataType}`
			: `https://go-stocks-lfffzpdrhq-uw.a.run.app/api/v1/cached/${symbol}/${dataType}`;
	};

	static _getCachedData = async (
		symbol: string,
		dataType: DataType
	): Promise<unknown> => {
		return fetch(this._getCachedDataUrl(symbol, dataType))
			.then((resp) => resp.json())
			.then((resp) => {
				const converter = getModelConverter(dataType);
				return converter ? converter(resp) : resp;
			});
	};

	// ===== Typed data getters ===== //

	static getDailyData = async (symbol: string): Promise<AVDaily> =>
		(await this._getCachedData(symbol, DataType.Daily)) as AVDaily;

	static getCompanyOverview = async (
		symbol: string
	): Promise<AVCompanyOverview> =>
		(await this._getCachedData(
			symbol,
			DataType.CompanyOverview
		)) as AVCompanyOverview;

	static getBalanceSheet = async (symbol: string): Promise<AVBalanceSheet> =>
		(await this._getCachedData(
			symbol,
			DataType.BalanceSheet
		)) as AVBalanceSheet;

	static getIncomeStatement = async (
		symbol: string
	): Promise<AVIncomeStatement> =>
		(await this._getCachedData(
			symbol,
			DataType.IncomeStatement
		)) as AVIncomeStatement;

	static getCashFlow = async (symbol: string): Promise<AVCashFlow> =>
		(await this._getCachedData(symbol, DataType.CashFlow)) as AVCashFlow;
}
