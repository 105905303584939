export const eligibleStocks = [
	"AAPL",
	"MSFT",
	"GOOG",
	"AMZN",
	"NVDA",
	"BRK-B",
	"META",
	"TSLA",
	"V",
	"UNH",
	"XOM",
	"JNJ",
	"LLY",
	"WMT",
	"JPM",
	"PG",
	"MA",
	"MRK",
	"CVX",
	"HD",
	"KO",
	"PEP",
	"ORCL",
	"AVGO",
	"ABBV",
	"COST",
	"BAC",
	"MCD",
	"PFE",
	"TMO",
	"CRM",
	"ABT",
	"CSCO",
	"NKE",
	"DIS",
	"DHR",
	"TMUS",
	"CMCSA",
	"VZ",
	"ADBE",
	"AMD",
	"NEE",
	"NFLX",
	"TXN",
	"PM",
	"UPS",
	"BMY",
	"WFC",
	"RTX",
	"MS",
	"HON",
	"AMGN",
	"INTC",
	"SBUX",
	"LOW",
	"T",
	"COP",
	"UNP",
	"INTU",
	"BA",
	"QCOM",
	"PLD",
	"SPGI",
	"LMT",
	"DE",
	"IBM",
	"AXP",
	"CAT",
	"ELV",
	"GE",
	"SYK",
	"GS",
	"MDLZ",
	"ISRG",
	"BKNG",
	"AMAT",
	"GILD",
	"BLK",
	"NOW",
	"ADI",
	"AMT",
	"VRTX",
	"C",
	"TJX",
	"CVS",
	"MMC",
	"ADP",
	"SCHW",
	"ZTS",
	"SO",
	"REGN",
	"MO",
	"PGR",
	"UBER",
	"CI",
	"HCA",
	"BSX",
	"DUK",
	"FISV",
	"TGT",
];

